import React, { useState, useEffect } from 'react';
import { S3 } from 'aws-sdk';
import * as XLSX from 'xlsx';
import { Form } from 'react-bootstrap'; // assuming you're using react-bootstrap for Form components
import  { useRef } from 'react';
import AWS from 'aws-sdk';
import 'font-awesome/css/font-awesome.min.css';

import { Container, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path
import { useAuth } from './AuthContext'

const bucket = config.WAPbucketName


Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}

function ViewCust() {

  const toolName='Workload Assessment Pro'
  const [excelData, setExcelData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [s3data, sets3data] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();


  useEffect(() => {
  
    fetchUserData();

  }, []);

  const fetchUserData = async () => {
    try {
  
     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
       } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };

  const handleUpdateDB = async (event) => {
    
    try {
    
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };


  useEffect(() => {

    console.log('Component mounted, useEffect executed');
    

    // Function to fetch file names from S3 bucket
    async function fetchS3FileNames() {
      const credentials = await Auth.currentCredentials();

       AWS.config.update({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: config.region,
      });

      console.log(credentials.accessKeyId)
      const s3 = new AWS.S3({ region: config.region, credentials: credentials });

      sets3data(s3);
      try {
        
        const s3ListObjects = await s3.listObjectsV2({
          Bucket: bucket
        }).promise();

        const names = s3ListObjects.Contents.map((item) => {
          
            const fileNameWithPath = item.Key; // Full file name with path
            // Check if the file is not from the specified folder
        if (!fileNameWithPath.startsWith('template/')) {
          const parts = fileNameWithPath.split('/');
          const fileName = parts[parts.length - 1]; // Extract file name from full key
          return fileName.split('.xlsx')[0]; // Remove file extension and return file name
      } else {
          return null; // Exclude files from specified folder
      }
  }).filter(name => name !== null && name.trim().length > 0); // Filter out null values and empty file names

  
          // Filter out empty file names
          const nonEmptyFileNames = names.filter((name) => name.trim().length > 0);

          
          setFileNames(nonEmptyFileNames);
      } catch (error) {
        console.error('Error fetching file names:', error);
      }
    }

    fetchS3FileNames();
  }, []);


  async function fetchExcelFile(selectedValue) {



  
    try {
      // Initialize AWS S3 client
      const s3 = new AWS.S3({ region: config.region, credentials: credentials });
      
      // Specify the S3 bucket and file key
      const params = {
        Bucket: bucket,
        Key: selectedValue + '.xlsx',
      };


      // Fetch the Excel file from S3
      const data = await s3.getObject(params).promise();
      const workbook = XLSX.read(data.Body, { type: 'buffer' }); // Parse Excel file
      const sheetName = workbook.SheetNames[0]; // Assume first sheet
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
      setExcelData(parsedData);
    } catch (error) {
      console.error('Error fetching Excel file:', error);
    }
  }

  

  // Function to handle field value changes
  const handleFieldChange = (label, value) => {
    // Handle field value changes here
    console.log(`Field ${label} changed to: ${value}`);
  };
  const selectedTemp = (selectedValue) => {
    handleUpdateDB()


    console.log('Selected value:', selectedValue);
    fetchExcelFile(selectedValue);

    console.log('Selected value:', selectedValue);
    //fetchFromS3(selectedFile);
    // You can perform any action with the selected value here
    // For example, update state or perform an API call
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  }

  
  return (

    <div >
      <h2 class="heading">Workload Assessment Pro</h2>
      <h3 class="heading2">View Customer Data</h3>
    
      
    <Container>

     

     
      <div class="product-description">
      Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. </div>
     
  
      <Form className="scrollable-form" onSubmit={handleSubmit}>
      

      <Form.Group className="form-field">
        <Form.Label>Select Customer</Form.Label>
        <Form.Control
          as="select"
          value={selectedFile}
          onChange={(e) => {

            const selectedValue = e.target.value;
            setSelectedFile(selectedValue);
            selectedTemp(selectedValue); // Call your function with the selected value
          }}
        >
          <option value="">Select a file</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {fileName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
      <div>

      <div>
      
      <Form >
        {excelData.map((row, index) => (
          <div key={index}>
            <Form.Group  controlId={`label_${index}`}>
              <Form.Label>{row[Object.keys(row)[0]]}</Form.Label>
            </Form.Group>
            <Form.Group  className="form-field" controlId={`value_${index}`}>
              <Form.Control
                type="text"
                value={row[Object.keys(row)[1]]}
                onChange={(e) => handleFieldChange(row[Object.keys(row)[0]], e.target.value)}
              />
            </Form.Group>
          </div>
        ))}
      </Form>
    </div>
</div>
      
    </Form.Group>
<br></br>
  
  
    </Form>
 



</Container>
 {/*
<div>&nbsp;</div>

<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
      </div>
    </div>


  */}
</div>


  );
}

export default ViewCust;
