import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import App from './App';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { BrowserRouter } from 'react-router-dom';

// Configure Amplify
Amplify.configure(awsconfig);

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
