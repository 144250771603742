import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';
import 'font-awesome/css/font-awesome.min.css';
import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path

import { useAuth } from './AuthContext'


const bucketName = config.WAPbucketName




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {
  const toolName='Workload Assessment Pro'
  const [formData, setFormData] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [label, setLabel] = useState('');
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [optionsInputVisible, setOptionsInputVisible] = useState(false);
  const [optionsInput, setOptionsInput] = useState('');
  const [fileStatus, setFileStatus] = useState('');
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();

  useEffect(() => {
  
    fetchUserData();

  }, []);

  const fetchUserData = async () => {
    try {
  
     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
       } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };

  const handleUpdateDB = async (event) => {
    
    try {
    
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };


  const addField = () => {
    
    const newField = {
      label: label,
      type: selectedFieldType,
      value: '',
      options: selectedFieldType === 'select' ? optionsInput.split(',') : [],
    };
    setFormFields([...formFields, newField]);
    setLabel('');
    setSelectedFieldType('');
    setOptionsInput('');
    setOptionsInputVisible(false);
  };

  const handleValueChange = (index, e) => {

    const { name, value } = e.target;
    const updatedData = [...formFields];
    updatedData[index] = { ...updatedData[index], [name]: value };
    setFormFields(updatedData);
  };

  const renderFormControl = (item, index) => {
    switch (item.type) {
      case 'text':
        return (
          <div key={index}>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              type="text"
              value={item.value}
              onChange={(e) => handleValueChange(index, e)}
            />
          </div>
        );
      case 'select':
        return (
          <div key={index}>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              as="select"
              value={item.value}
              onChange={(e) => handleValueChange(index, e)}
            >
              <option value="">Select an option</option>
              {item.options.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    handleUpdateDB()
    const formData = {
      templateName: templateName,
      fields: formFields,
    };

    // Convert the form data to JSON string
    const jsonData = JSON.stringify(formData);

    // Replace these placeholders with your actual AWS credentials and bucket information
    
    AWS.config.update({
     accessKeyId: credentials.accessKeyId,
     secretAccessKey: credentials.secretAccessKey,
     sessionToken: credentials.sessionToken,
     region: config.region,
   });

    const s3 = new AWS.S3({ region: config.region, credentials: credentials });
    const fileName = `template/${templateName}.json`; 
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: jsonData,
      ContentType: 'application/json',
    
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setFileStatus('failed! Please try again!\n');
        console.error(err);
      } else {
        console.log('File uploaded successfully:', data);
        setFileStatus('Created Successfully.\n');
        setFormData(null);
        setTemplateName('');
        
        setFormFields([]);

      }
    });
  };



  const hasNonEmptyText = /\S/.test(fileStatus);

  return (
    <div >
      <h2 className="heading">Workload Assessment Pro</h2>
      <h3 className="heading2">Add Template</h3>
    
      
    <Container>

     

     
      <div className="product-description">
      Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. </div>
     
  <Form className="scrollable-form" >

     <Form.Group>
        <Form.Label>Template Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Template name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)} // Update the template name state
        />
      </Form.Group>


      
      <Form.Group>
        <Form.Label>Label</Form.Label>
        <Form.Control
          type="text"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Select Field Type</Form.Label>
        <Form.Control
          as="select"
          value={selectedFieldType}
          onChange={(e) => {
            setSelectedFieldType(e.target.value);
            if (e.target.value === 'select') {
              setOptionsInputVisible(true);
            } else {
              setOptionsInputVisible(false);
            }
          }}
        >
          <option value="">Select field type</option>
          <option value="text">Text</option>
          <option value="select">Select</option>
          {/* Add other form control types as needed */}
        </Form.Control>
      </Form.Group>

      {optionsInputVisible && (
        <Form.Group>
          <Form.Label>Enter Options for Select (comma-separated)</Form.Label>
          <Form.Control
            type="text"
            value={optionsInput}
            onChange={(e) => setOptionsInput(e.target.value)}
          />
        </Form.Group>
      )}

     

      <br />
      <Button  className="custom-button" variant="primary" onClick={addField} > 
        Add Field
      </Button>
      

     

      <Form.Group>
        {formFields.map((field, index) => renderFormControl(field, index))}
      </Form.Group>

<br>
</br>
      <Button   variant="primary" onClick={handleSubmit}>
        Save Template
      </Button>
    </Form>
    {hasNonEmptyText && (
        <div className="success-message with-background">
          {fileStatus}
        </div>
      )}
</Container>
<div>&nbsp;</div>

<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
      </div>
    </div>




</div>
  );
}

export default App;
