import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';


import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';

import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path
import config from './appenvdata.json';
import { useAuth } from './AuthContext';

Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {
  const toolName = 'Maptagger'; 
  const { token, userAttributes, logout } = useAuth();
  const [downloadLink, setDownloadLink] = useState('');
  const bucketName = config.MAPtaggingBucketName ; 

  const s3 = new AWS.S3({});
  const fileName = config.MAPtaggingFileName
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    fetchUserData();


    const getPresignedUrl = async () => {
      try {
     
      const presignedUrl = await generatePresignedUrl(fileName);
    
      setDownloadLink(presignedUrl)
    } catch (error) {
   
      setDownloadLink('');
    }
    };

    getPresignedUrl();
  }, []); // Empty dependency array to run only on mount

 const fetchUserData = async () => {
    try {

     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
      //console.log('Cognito Username:', userData.attributes.email); // Print the Cognito username
    } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };
  const geturl = async () => {
    try {
     
      const presignedUrl = await generatePresignedUrl(fileName);
    
      setDownloadLink(presignedUrl)
    } catch (error) {
   
      setDownloadLink('');
    }
  };

  const generatePresignedUrl = async (fileName) => {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Expires: 3600, // URL expiration time in seconds (adjust as needed)
    };

    const presignedUrl = await s3.getSignedUrlPromise('getObject', params);
    return presignedUrl;
  };


   geturl();

   AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: config.region,
  
  });
  const handleUpdateDB = async (event) => {
    event.preventDefault(); // Prevent default anchor behavior
 
    // Call the function to add entry to DynamoDB
    
    await addEntryToDynamoDB('',toolName,userEmail, userFirstName,userLastName);

  
    // After adding to DynamoDB, you can proceed with the download
    window.location.href = downloadLink; // Initiate download
  };


  return (
    <div>
      <h2 className="heading">MAP Smart Tagger</h2>
      <Container>
        <div className="product-description">
          This tool will help in automating the process of assigning MAP tags.
        </div>

        <div className="container1">
          <div className="product-description">
            <b>Download the Map tagging assets</b>
          </div>

          <div className="file-input-container">
            <div>
              <a
                className="amazon-file-input1"
                href={downloadLink}
                onClick={handleUpdateDB}
                download
              >
                <span>Download</span>
              </a>
            </div>
          </div>

          <div>
            <br />
          </div>

          {/* Expandable README Section */}
          <div >
            <button onClick={toggleExpand} className="expandable-button">
              {isExpanded ? 'Hide README' : 'Show README'}
            </button>
            {isExpanded && (
              <div className="readme-content">
                
                <h3>README</h3>
               
                <li>
  After downloading the file, unzip it to access two components:
  <ul>
    <li>Lambda function zip file: <b>MAPTagging.zip</b></li>
    <li>CloudFormation Template: <b>MAPTagging.yaml</b></li>
  </ul>
</li>
<li>Step 1: Create an S3 bucket and upload the <b>MAPTagging.zip</b> file.</li>
<li>
  Step 2: Set up the CloudFormation Template using <b>MAPTagging.yaml</b> with the following parameters
  <ul>
    <li><b>BucketName </b>: Your Bucket Name</li>
    <li><b>LambdaExecutionRoleName </b>: MAPTagging</li>
    <li><b>LambdaFunctionName </b>: MAPTagging</li>
    <li><b>S3Key </b>: MAPTagging.zip</li>
    <li><b>TagKey </b>: map-migrated</li>
    <li><b>TagValue </b>: Your MAP Value</li>
  </ul>
</li>
<li>
  This CloudFormation will deploy a Lambda function along with the necessary IAM Role.
</li>
<li>Finally, test the Lambda function to ensure it tags the resources correctly.</li>

              </div>
            )}
          </div>

        </div>
      </Container>

      <div className="product-description">
        <div className="container">
          <h3>About</h3>
          <p>
            Owner: <span id="version-number"><b><a className="phone-link" href="https://phonetool.amazon.com/users/sujallur">Sujana Alluru</a></b></span>
          </p>
          <div className="progress-text"></div>
          <p>Current Version: <span id="version-number"><b>1.0</b></span></p>
          <p>Last Updated: <span id="last-updated-date"><b>September 2024</b></span></p>
          <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
          <br />
          <h3>Version History</h3>
          <div className="version-wrapper">
            <p><span id="version-number"><b>Version 1.0</b></span></p>
            <p>This version provides an automated MAP tagging solution for the following AWS services</p>
            <ul>
              <li>EC2</li>
              <li>EBS</li>
              <li>EIP</li>
              <li>Snapshots</li>
              <li>EKS</li>
              <li>ECS</li>
              <li>CloudWatch</li>
              <li>RDS</li>
              <li>S3</li>
              <li>Transfer Family</li>
              <li>Direct Connect</li>
              <li>ELB</li>
              <li>Kinesis</li>
            </ul>
          </div>
          
          <h3>🚀 Updates coming soon...</h3>
          <p>Upcoming features will include support for remaining MAP eligible services</p>
        </div>
        <br />
      </div>
    </div>
  );
};

export default App;
