import React, { useState, useEffect } from 'react';
import { Form, Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import * as XLSX from 'xlsx';
import {  Button } from 'react-bootstrap';
import ExcelJS from 'exceljs';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path
import { useAuth } from './AuthContext'


const bucketName = config.WAPbucketName
const region =config.region

function UpdateCust() {

  const toolName='Workload Assessment Pro'
  const [excelData, setExcelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [tempName, setTempName] = useState('');
  const [templateData, setTemplateData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [fileStatus, setFileStatus] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();


  useEffect(() => {
  
    fetchUserData();

  }, []);

  const fetchUserData = async () => {
    try {
  
     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
       } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };

  const handleUpdateDB = async (event) => {
    
    try {
    
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };



  useEffect(() => {
    async function fetchS3FileNames() {
      try {
        const credentials = await Auth.currentCredentials();
        const s3 = new AWS.S3({ region: region, credentials });

        const s3ListObjects = await s3.listObjectsV2({ Bucket: bucketName}).promise();
        const names = s3ListObjects.Contents.map((item) => {
          const fileNameWithPath = item.Key;
          if (!fileNameWithPath.startsWith('template/')) {
            const parts = fileNameWithPath.split('/');
            const fileName = parts[parts.length - 1];
            return fileName.split('.xlsx')[0];
          } else {
            return null;
          }
        }).filter(name => name !== null && name.trim().length > 0);

        setFileNames(names);
        checkButtonStatus();
      } catch (error) {
        console.error('Error fetching file names:', error);
      }
    }

    fetchS3FileNames();
  }, []);

  
  async function fetchExcelFile(selectedValue) {
    try {
      const credentials = await Auth.currentCredentials();
      const s3 = new AWS.S3({ region: region, credentials });
    
      const params = {
        Bucket: bucketName,
        Key: selectedValue + '.xlsx',
      };

      const data = await s3.getObject(params).promise();
      const workbook = XLSX.read(data.Body, { type: 'buffer' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      
      setExcelData(parsedData);

      const dataMap = {};
      parsedData.forEach(row => {
        const key = row['Detail'];
        const value = row['Value'];
        dataMap[key] = value;
      });

      const firstColumnValue = 'TemplateName';
      const tempName = dataMap[firstColumnValue] || '';
      setTempName(tempName);
     
    } catch (error) {
      console.error('Error fetching Excel file:', error);
    }
  }

  async function fetchJsonTemplate(tempName) {

    try {
      const credentials = await Auth.currentCredentials();
      const s3 = new AWS.S3({ region: region, credentials });

      const params = {
        Bucket: bucketName,
        Key: `template/${tempName}.json`,
      };

      const data = await s3.getObject(params).promise();
      setTemplateData(JSON.parse(data.Body.toString('utf-8')));
    } catch (error) {
      console.error('Error fetching JSON template:', error);
    }
  }

  useEffect(() => {
    if (tempName) {
      fetchJsonTemplate(tempName);
    }
  }, [tempName]);

  useEffect(() => {
    if (templateData && Array.isArray(templateData.fields) && excelData.length > 0) {
      const updatedFormValues = {};
  
      for (const field of templateData.fields) {
        const excelRow = excelData.find(row => row.Detail === field.label);
        let fieldValue = '';
  
        if (excelRow) {
          fieldValue = excelRow.Value !== undefined ? excelRow.Value : '';
        } else {
          fieldValue = field.defaultValue || '';
        }
  
        // Set the field label as the key and the field value from Excel data as the value
        updatedFormValues[field.label] = fieldValue;
      }
  
      setFormValues(updatedFormValues);
    }
  }, [templateData, excelData]);
  
  
  
  
  
  
  

  const selectedTemp = async (selectedValue) => {
    setCustomerName(selectedValue)
    //setFormValues({});
    setSelectedFile(selectedValue);
    // Reset form values when a new customer is selected
    await fetchExcelFile(selectedValue);
  };


  const handleFieldChange = (name, value) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [name]: value
    }));
  };
  
  const checkButtonStatus = () => {
    setIsButtonEnabled(isFormValid());
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!templateData || !templateData.fields) {
      console.error('Template data is not available.');
      return;
    }
  
    console.log('Inside handleSubmit');
  
    try {

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Form Data');
      console.log('Worksheet created');
  
      const labels = [];
      const values = [];

      labels.push('Detail');
      values.push('Value');
      
      labels.push('Customer Name');
      values.push(customerName);

      labels.push('TemplateName');
      values.push(tempName);
      
      
      templateData.fields.forEach((field) => {
        labels.push(field.label);
        // Use formValues to get the value corresponding to the label
        values.push(formValues[field.label] || ''); // Use empty string if value is null or undefined
      });

      console.log('Labels:', labels);
      console.log('Values:', values);
      worksheet.getColumn(1).values = labels;
      worksheet.getColumn(2).values = values;

      //worksheet.addRow(labels);
      //worksheet.addRow(values);
      console.log('Rows added to worksheet');
  
      const excelBuffer = await workbook.xlsx.writeBuffer();
      console.log('Excel buffer created');
      const credentials = await Auth.currentCredentials();
  
      const s3 = new AWS.S3({ region: region, credentials });
      console.log('S3 instance created');
  
      let val = customerName;

      
      
      console.log('Customer Name found:', val);
     
      if (val !== '') {
        const params = {
          Bucket: bucketName,
          Key: val + '.xlsx',
          Body: excelBuffer,
          ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
  
        const uploadResult = await s3.upload(params).promise();
        console.log('File uploaded to S3:', uploadResult.Location);
     
        setFileStatus('Submitted Successfully.\n');
        
        setTemplateData('');
         setCustomerName('');
        setSelectedFile('');
        setFormValues('')

      } else {
        console.error('val not found');
        setFileStatus('failed! Please try again!\n');
      }
    }catch (error) {
      console.error('Error:', error);
      setFileStatus('failed! Please try again!\n');
    }
  };

  
  


  const isFormValid = () => {
    // Add your logic to check if all required fields are filled
    return (
   
      selectedFile.trim() !== '' &&
      selectedFile.trim() !== 'selectopt' &&
      templateData && 
      templateData.fields.every((field) => field.value.trim() !== '')
      

      
    );
  };

  const hasNonEmptyText = /\S/.test(fileStatus);
  return (
    <div>
      <h2 className="heading">Workload Assessment Pro</h2>
      <h3 className="heading2">Update Customer Data</h3>
      <Container>
        <div className="product-description">
        Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. 
     
     </div>
       <Form className="scrollable-form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Select Customer</Form.Label>
            <Form.Control
              as="select"
              value={selectedFile}
              onChange={(e) => selectedTemp(e.target.value)}
            >
              <option value="">Select a file</option>
              {fileNames.map((fileName, index) => (
                <option key={index} value={fileName}>{fileName}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <div>
            <Form>
  {templateData.fields && templateData.fields.map((field, index) => (
    <div key={index} className="form-field">
      <Form.Label>{field.label}</Form.Label>
      {field.type === 'text' ? (
        <Form.Control
          type="text"
          value={formValues[field.label]} // Use field label as key to access value
          required={field.required}
          onChange={(e) => handleFieldChange(field.label, e.target.value)} // Pass field label as identifier
        />

          


      ) : (
        <Form.Control
          as="select"
          value={formValues[field.label] || ''} // Ensure value is never undefined
          required={field.required}
          onChange={(e) => handleFieldChange(field.label, e.target.value)} // Pass field label as identifier
        >
          <option value="">Select</option>
          {field.options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>



      )}
    </div>
  ))}
  
</Form> </div>
            
          </Form.Group>

          <Button variant="primary" type="submit" style={{ marginTop: '10px' }} onClick={handleUpdateDB} >
          Submit
        </Button>

        </Form>
        {hasNonEmptyText && (
        <div className="success-message with-background">
          {fileStatus}
        </div>
      )}
      </Container>
       {/*
      <div>&nbsp;</div>

<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
      </div>
    </div>*/}
    </div>
  );
}

export default UpdateCust;
