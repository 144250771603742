import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

 
import * as XLSX from 'xlsx';



import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {
  let accessKeyId;
  let secretAccessKey;

  const [secretValue, setSecretValue] = useState('');
  useEffect(() => {
    async function fetchSecret() {
     
      try {
        // Sign in or authenticate the user, if necessary
       // await Auth.signIn('username', 'password'); // Replace with your actual username and password
       const credentials = await Auth.currentCredentials();

       AWS.config.update({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: config.region,

      });

      
        // Create an AWS Secrets Manager client
        const secretsManager = new AWS.SecretsManager();

        // Specify the name of the secret you want to access
        const secretName = config.secretName; // Replace with the name of your secret

        // Access the secret value
        const secretValue = await secretsManager.getSecretValue({ SecretId: secretName }).promise();
        
        
        if (secretValue.SecretString) {
          const secretData = JSON.parse(secretValue.SecretString);
          setSecretValue(secretData.yourSecretKey);
         
          accessKeyId=secretData.accesskey
          secretAccessKey=secretData.secretkey
          

          
          // Replace with the actual key name in your secret
        } else {
          console.log('No secret value found.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchSecret();

  }, []);
  const [userInput, setUserInput] = useState({
    migrationType: '',
    cloudProvider: '',
    detailedTCO: false,
    analysis7R: false,
    optimizedTCO: false,
    backupDR: false,
    appModernization: false,
    iaasService: false,
    paasService: false,
    appInputs: false
  });

  const [validationResult, setValidationResult] = useState('');
  const [sourceCloud, setsourceCloud] = useState('');
  const [excelPath, setExcelPath] = useState('');
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setUserInput(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const fetchExcelFileFromS3 = async () => {
    // Initialize AWS S3
    const s3 = new AWS.S3({
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey, 
    });

    const params = {
      Bucket: 'estimate-validation-dev',
      Key: 'Assets/Pythia-Scenarios3.xlsx' // Replace with your actual file name
    };


    try {
      const data = await s3.getObject(params).promise();
      return data.Body;
    } catch (error) {
      console.error('Error fetching Excel file:', error);
      return null;
    }
  };

  const validateUserData = async () => {
    try {
      // Refresh credentials
      const freshCredentials = await Auth.currentCredentials();
      configureAWS(freshCredentials, config.region);
  
      const excelFile = await fetchExcelFileFromS3();
  
      if (!excelFile) {
        setValidationResult('Excel file not found');
        return;
      }
  
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(sheet);
  
      const matchedRow = excelData.find(row => {
        let matchFound = true;
        for (const key in userInput) {
          const userValue = userInput[key];
          const cellValue = row[key];
          const formattedUserValue = userValue === true ? 'Yes' : userValue === false ? 'No' : userValue.trim();
          const formattedCellValue = cellValue === true ? 'Yes' : cellValue === false ? 'No' : cellValue.trim();
          console.log(`User value for ${key}: ${formattedUserValue}`);
          console.log(`Cell value for ${key}: ${formattedCellValue}`);
  
          setsourceCloud('Azure');
          if (formattedCellValue !== formattedUserValue) {
            matchFound = false;
            break;
          }
        }
        if (matchFound) {
          return true;
        }
      });
  
      if (matchedRow) {
        const excelPath = matchedRow['excelPath'];
        setExcelPath(excelPath);
        console.log('Matched Row:', matchedRow);
        console.log('Excel Path:', excelPath);
  
        // Now you can use 'excelPath' to provide the respective path to the user in the frontend
        // For example, you can set it in the state and display it in the UI
        setValidationResult(excelPath); 
        window.open(excelPath, '_blank');
        
  
       
       
        
        
        console.log('Matched Row:', matchedRow);
        // Do something with the matched row
      }  else {
        console.log('No matching row found');
      }
    } catch (error) {
      console.error('Error fetching Excel file:', error);
    }
  };
  


  const handleDownload = () => {
    
  };

  return (
    <div >
      <h2 class="heading">Migration Data Collection Wizard</h2>
    
      
    <Container>

     

     
      <div class="product-description">
      This tool helps with downloadable data collection discovery templates for the Migration Assessment Phase. </div>
     
       <div className="container1">
  
  
       <div className="amazon-form">
        <br></br>
  <label className="amazon-checkbox-label">
    Migration Type:
    <select
      className="amazon-select"
      name="migrationType"
      value={userInput.migrationType}
      onChange={handleChange}
    >
      <option value="">Select</option>
      <option value="Public">Public</option>
      <option value="Private">Private</option>
      <option value="On-premises">On-premises</option>
    </select>
  </label>

  {userInput.migrationType === 'Public' && (
   
     <div>
    <label >
      Cloud Provider:
      <select
        className="amazon-select"
        name="cloudProvider"
        value={userInput.cloudProvider}
        onChange={handleChange}
      >
        <option value="">Select</option>
        <option value="Azure">Azure</option>
        <option value="AWS">AWS</option>
        <option value="Alicloud">Alicloud</option>
      </select>
    </label>
    <br></br>
    </div>
  )}
  
  {['Private', 'On-premises'].includes(userInput.migrationType) && (
    
    <div>
      
      <label className="amazon-checkbox-label">
        Infrastructure Inputs (x86):
        <input
          className="amazon-checkbox"
          type="checkbox"
          name="infrastructureInputs"
          checked={userInput.infrastructureInputs}
          onChange={handleChange}
        />
      </label>
      {(userInput.migrationType === 'Private' || userInput.migrationType === 'On-premises') && (
      <br />
    )}


<label className="amazon-checkbox-label">
      Infrastructure Inputs (non-x86):
     
        <input
      className="amazon-checkbox"
      type="checkbox"
      name="infrastructureInputsnonx86"
      checked={userInput.infrastructureInputsnonx86}
      onChange={handleChange}
    />
    </label>
  
     

      {(userInput.migrationType === 'Private' || userInput.migrationType === 'On-premises') && (
      <br />
    )}
 <label className="amazon-checkbox-label">
        Does it contain Cost Inputs? :
        <input
          className="amazon-checkbox"
          type="checkbox"
          name="CostInputs"
          checked={userInput.CostInputs}
          onChange={handleChange}
        />
      </label>
      {(userInput.migrationType === 'Private' || userInput.migrationType === 'On-premises') && (
      <br />
    )}
    </div>

)}

{(userInput.migrationType === 'Public') && (
      <br />
    )}
  <label className="amazon-checkbox-label">
    Detailed TCO:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="detailedTCO"
      checked={userInput.detailedTCO}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    7R Analysis:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="analysis7R"
      checked={userInput.analysis7R}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    Optimised TCO:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="optimizedTCO"
      checked={userInput.optimizedTCO}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    Backup/DR:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="backupDR"
      checked={userInput.backupDR}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    App Modernization:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="appModernization"
      checked={userInput.appModernization}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    IaaS Service:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="iaasService"
      checked={userInput.iaasService}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    PaaS Service:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="paasService"
      checked={userInput.paasService}
      onChange={handleChange}
    />
  </label>
  <br />
  <label className="amazon-checkbox-label">
    Application Inputs:
    <input
      className="amazon-checkbox"
      type="checkbox"
      name="appInputs"
      checked={userInput.appInputs}
      onChange={handleChange}
    />
  </label>
  <br />
  <button className="amazon-button" onClick={validateUserData}>Generate Template</button>
  
</div>




    <div>
      <br></br>
    
    </div>
   
  </div>
 
</Container>





<div class="left-aligned-container">
        <h2>About</h2>
        <h3>About</h3>
        
        <p>Owner: <span id="version-number">  <a class="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></span></p>
       
        <div class="progress-text">{/*<span className="tooltip" data-status="Inprogress"><FontAwesomeIcon icon={faCircle} className="not-started-icon amber-icon" /></span>*/}
        This tool is currently being developed. Tentative ETA - Q2 2024</div>
        {/* 
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
        */}
      </div>
    </div>





  );
}

export default App;
