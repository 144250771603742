import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

import 'font-awesome/css/font-awesome.min.css';


import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {

  

  return (
    <div >
     
    
      
    <Container>

     

</Container>







</div>
  );
}

export default App;
