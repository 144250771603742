import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

 

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';






Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {
  const [fileCount, setFileCount] = useState(0);
  

  
  const bucketName = config.WAPbucketName
  const region = config.region
  
  
  const [s3data, sets3data] = useState(null);
 
     const s3 = new AWS.S3({ region: config.region, credentials: credentials });


    const params = {
      Bucket: bucketName,
      
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error('Error listing S3 objects:', err);
        return;
      }

      const xlsxFiles = data.Contents.filter(file => file.Key.endsWith('.xlsx'));

      // Count the number of filtered files
     const numberOfXlsxFiles = xlsxFiles.length;

     
    
      setFileCount(numberOfXlsxFiles);
      
    });




  return (
    <div >
      <h2 class="heading">Workload Assessment Pro</h2>
    
      
    <Container>

     

     
      <div class="product-description">
      Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. </div>
     
  
 
</Container>


<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>{fileCount}</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>
     
      &nbsp;&nbsp;1. Add Template: Allows users to create new templates for customer assessments.<br />
      &nbsp;&nbsp;2. View Template: Enables users to view existing templates for customer assessments.<br />
      &nbsp;&nbsp;3. Update Template: Provides functionality to update existing templates for customer assessments.<br />
      &nbsp;&nbsp;4. Add Customer: Allows users to add new customer information.<br />
      &nbsp;&nbsp;5. View Customer: Enables users to view existing customer information.<br />
      &nbsp;&nbsp;6. Update Customer: Provides functionality to update existing customer information.<br />
    </p>
        </div>
        
        <br></br>
        
        <h3>🚀 Updates coming soon... </h3>
       
        <p> 1. Delete Customer: Option to delete existing customer information.<br />
        2. Delete Template: Option to delete existing templates for customer assessments.<br />
        3. Add Feedback: Option for users to submit feedback for the tool.<br />
        4. Download Templates: No more starting from scratch! Pick from ready-made templates or create your own to match what you need.<br />
        5. Export Customer Data: Analyze data offline or share it hassle-free! Export customer info or entire datasets in CSV, Excel.
     </p>
      </div>
    </div>
</div>
  );
}

export default App;
