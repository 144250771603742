import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';
import 'font-awesome/css/font-awesome.min.css';
import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path
import { useAuth } from './AuthContext'

const bucketName = config.WAPbucketName




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function UpdateTemp() {

  const toolName='Workload Assessment Pro'
  const [label, setLabel] = useState('');
  const [selectedFieldType, setSelectedFieldType] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [formData, setFormData] = useState(null);
  const [optionsInputVisible, setOptionsInputVisible] = useState(false);
  const [optionsInput, setOptionsInput] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [s3data, sets3data] = useState(null);

  const [formFields, setFormFields] = useState([]);
  const [fileStatus, setFileStatus] = useState('');
  const [templateData, setTemplateData] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();


  useEffect(() => {
  
    fetchUserData();

  }, []);

  const fetchUserData = async () => {
    try {
  
     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
       } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };

  const handleUpdateDB = async (event) => {
    
    try {
    
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };



  useEffect(() => {


    const fetchData = async () => {
      const data = await fetchFileFromS3();
      setFormData(data);
    };
  
    fetchData();
  
  
    
    console.log('Component mounted, useEffect executed');
    
  
    // Function to fetch file names from S3 bucket
    async function fetchS3FileNames() {
      const credentials = await Auth.currentCredentials();
  
       AWS.config.update({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: config.region,
      });
      const s3 = new AWS.S3({ region: config.region, credentials: credentials });
  
      sets3data(s3);
      try {
        const s3ListObjects = await s3.listObjectsV2({
          Bucket: bucketName,
           Prefix: 'template', 
        }).promise();
  
        const names = s3ListObjects.Contents.map((item) => {
            const fileNameWithPath = item.Key; // Full file name with path
            const parts = fileNameWithPath.split('/');
            const fileName = parts[parts.length - 1]; // Extract file name from full key
            return fileName.split('.json')[0]; // Remove .json extension
          });
  
          // Filter out empty file names
          const nonEmptyFileNames = names.filter((name) => name.trim().length > 0);
  
          
          setFileNames(nonEmptyFileNames);
      } catch (error) {
        console.error('Error fetching file names:', error);
      }
    }
  
    fetchS3FileNames();
  }, []);


  const fetchFileFromS3 = async () => {

    const s3 = new AWS.S3({ region: config.region, credentials: credentials }); 
   
    const params = {
      Bucket: bucketName,
      Key: 'template/'+selectedFile+'.json',
    };
  
    try {
      const data = await s3.getObject(params).promise();
      return JSON.parse(data.Body.toString());
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  };
  
  const uploadFileToS3 = async (jsonData) => {
    const s3 = new AWS.S3({ region: config.region, credentials: credentials });
    const params = {
      Bucket: bucketName,
      Key: 'template/'+selectedFile+'.json',
      Body: JSON.stringify(jsonData),
      ContentType: 'application/json',
    };
  
    try {
      await s3.putObject(params).promise();
      console.log('File uploaded successfully');
      setFileStatus('Submitted Successfully.\n');
      setSelectedFile('')
      setTemplateData('');
        
        setSelectedFile('');
        setFormData('')
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  
  
  const handleFieldChange = (index, fieldKey, value) => {
    const updatedFormData = { ...formData };
    if (fieldKey === 'options') {
      // Handle options separately to ensure correct array structure
      updatedFormData.fields[index].options = value.split(',').map((option) => option.trim());
    } else {
      updatedFormData.fields[index][fieldKey] = value;
    }
    setFormData(updatedFormData);
  };

  const addField = () => {

    const newField = {
      label: label,
      type: selectedFieldType,
      value: '',
      options: selectedFieldType === 'select' ? optionsInput.split(',') : [],
    };
    setFormFields([...formFields, newField]);
    setLabel('');
    setSelectedFieldType('');
    setOptionsInput('');
    setOptionsInputVisible(false);


    // Implement your logic for adding a new field
  };

  const renderFormControls = () => {
    if (formData && formData.fields) {
      return formData.fields.map((field, index) => (
        <div key={index}>
          <Form.Group className="form-field">
            <Form.Label>Label</Form.Label>
            <Form.Control
              type="text"
              value={field.label}
              onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
            />
          </Form.Group>
          {field.type === 'select' && (
            <Form.Group className="form-field">
              <Form.Label>Options (comma-separated)</Form.Label>
              <Form.Control
                type="text"
                value={field.options.join(', ')}
                onChange={(e) => handleFieldChange(index, 'options', e.target.value)}
              />
            </Form.Group>
          )}
        </div>
      ));
    } else {
      return null;
    }
  };

  const renderFormControl = (item, index) => {
    switch (item.type) {
      case 'text':
        return (
          <div key={index}>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              type="text"
              value={item.value}
              onChange={(e) => handleValueChange(index, e)}
            />
          </div>
        );
      case 'select':
        return (
          <div key={index}>
            <Form.Label>{item.label}</Form.Label>
            <Form.Control
              as="select"
              value={item.value}
              onChange={(e) => handleValueChange(index, e)}
            >
              <option value="">Select an option</option>
              {item.options.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </Form.Control>
          </div>
        );
      default:
        return null;
    }
  };


  
  const handleValueChange = (index, e) => {

    const { name, value } = e.target;
    const updatedData = [...formFields];
    updatedData[index] = { ...updatedData[index], [name]: value };
    setFormFields(updatedData);
  };
  const handleSubmit = () => {
    handleUpdateDB();
    
    const formData1 = {
      templateName: templateName,
      fields: [ ...formData.fields,...formFields],
    }; 
    
    
    uploadFileToS3(formData1);
  };
  

  
  
  const selectedTemp = (selectedValue) => {
    console.log('Selected value:', selectedValue);
    fetchFromS3(selectedValue);

    console.log('Selected value:', selectedValue);
    //fetchFromS3(selectedFile);
    // You can perform any action with the selected value here
    // For example, update state or perform an API call
  };

  const fetchFromS3 = (selectedFile) => {

  
    
    AWS.config.update({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: config.region,
    });


const s3 = s3data
  

    
    const params = {
      Bucket: bucketName,
      Key: 'template/'+selectedFile+'.json',
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error fetching template:', err);
      } else {

        const jsonData = JSON.parse(data.Body.toString());
        setFormData(jsonData);



        const template = JSON.parse(data.Body.toString('utf-8'));
        setTemplateData(template);
      }
    });
  };
  const hasNonEmptyText = /\S/.test(fileStatus);
  return (
    <div >
      <h2 class="heading">Workload Assessment Pro</h2>
      <h3 class="heading2">Update Template</h3>
    
      
    <Container>

     

     
      <div class="product-description">
      Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. </div>
     
      <Form  className="scrollable-form" onSubmit={handleSubmit}>
      

      <Form.Group>
        <Form.Label>Select Template</Form.Label>
        <Form.Control
          as="select"
          value={selectedFile}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setSelectedFile(selectedValue);
            selectedTemp(selectedValue); // Call your function with the selected value
          }}
        >
          <option value="">Select a file</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {fileName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>


      </Form.Group>
     


      
      <Form.Group>
        <Form.Label>Label</Form.Label>
        <Form.Control
          type="text"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Select Field Type</Form.Label>
        <Form.Control
          as="select"
          value={selectedFieldType}
          onChange={(e) => {
            setSelectedFieldType(e.target.value);
            if (e.target.value === 'select') {
              setOptionsInputVisible(true);
            } else {
              setOptionsInputVisible(false);
            }
          }}
        >
          <option value="">Select field type</option>
          <option value="text">Text</option>
          <option value="select">Select</option>
          {/* Add other form control types as needed */}
        </Form.Control>
      </Form.Group>

      {optionsInputVisible && (
        <Form.Group>
          <Form.Label>Enter Options for Select (comma-separated)</Form.Label>
          <Form.Control
            type="text"
            value={optionsInput}
            onChange={(e) => setOptionsInput(e.target.value)}
          />
        </Form.Group>
      )}
<br></br>
     
<div> <Button variant="primary" onClick={addField}>
        Add Field
      </Button></div>
      <br></br>

      <Form.Group>{renderFormControls()}</Form.Group>
      <Form.Group>
        {formFields.map((field, index) => renderFormControl(field, index))}
      </Form.Group>
   
<div>

  <Button variant="primary" type="submit" onClick={handleSubmit}>
          Update Template
        </Button>
        </div>
    
    </Form>
    {hasNonEmptyText && (
        <div className="success-message with-background">
          {fileStatus}
        </div>
      )}
</Container>
 {/*
<div>&nbsp;</div>

<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
      </div>
    </div>



  */}
</div>
  );
}

export default UpdateTemp;
