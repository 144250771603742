import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

import ExcelJS from 'exceljs';

import config from './appenvdata.json';

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


import { addEntryToDynamoDB } from './dynamoDbService'; // Adjust the import path

import { useAuth } from './AuthContext';
import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


const bucket = config.WAPbucketName




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {

  const toolName='Workload Assessment Pro'
  const [formData, setFormData] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  
  const bucketName =bucket
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [fileStatus, setFileStatus] = useState('');
  const [templateData, setTemplateData] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const { token, userAttributes, logout } = useAuth();


  useEffect(() => {
  
    fetchUserData();

  }, []);

  const fetchUserData = async () => {
    try {
  
     
     setUserEmail(userAttributes.email);
     setUserFirstName(userAttributes.given_name);
     setUserLastName(userAttributes.family_name);
   
       } catch (error) {
      console.log('Error fetching user data: ', error);
    }
  };

  const handleUpdateDB = async (event) => {
    
    try {
    
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };

  const isFormValid = () => {
    // Add your logic to check if all required fields are filled
    return (
      customerName.trim() !== '' &&
      selectedFile.trim() !== '' &&
      selectedFile.trim() !== 'selectopt' &&
      formData && 
      formData.fields.every((field) => field.value.trim() !== '')
    );
  };

  const checkButtonStatus = () => {
    setIsButtonEnabled(isFormValid());
  };


  const [s3data, sets3data] = useState(null);
  

  useEffect(() => {
    
 
    

    // Function to fetch file names from S3 bucket
    async function fetchS3FileNames() {
      const credentials = await Auth.currentCredentials();

       AWS.config.update({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: config.region,
      });
      const s3 = new AWS.S3({ region: config.region, credentials: credentials });

      sets3data(s3);
      try {
        const s3ListObjects = await s3.listObjectsV2({
          Bucket: bucket,
           Prefix: 'template', 
        }).promise();

        const names = s3ListObjects.Contents.map((item) => {
            const fileNameWithPath = item.Key; // Full file name with path
            const parts = fileNameWithPath.split('/');
            const fileName = parts[parts.length - 1]; // Extract file name from full key
            return fileName.split('.json')[0]; // Remove .json extension
          });
  
          // Filter out empty file names
          const nonEmptyFileNames = names.filter((name) => name.trim().length > 0);

          
          setFileNames(nonEmptyFileNames);
      } catch (error) {
        console.error('Error fetching file names:', error);
      }
    }
    checkButtonStatus();
    fetchS3FileNames();
  }, [customerName, selectedFile, formData]);

  const fetchFromS3 = (selectedFile) => {

  
    
    AWS.config.update({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: config.region,
    });


const s3 = s3data
  

    
    const params = {
      Bucket: bucketName,
      Key: 'template/'+selectedFile+'.json',
    };

    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error fetching template:', err);
      } else {

        const jsonData = JSON.parse(data.Body.toString());
        setFormData(jsonData);



        const template = JSON.parse(data.Body.toString('utf-8'));
        setTemplateData(template);
      }
    });
  };

  const selectedTemp = (selectedValue) => {
   
    fetchFromS3(selectedValue);

    
    //fetchFromS3(selectedFile);
    // You can perform any action with the selected value here
    // For example, update state or perform an API call
  };

  const handleFieldChange = (index, newValue) => {
    const updatedFields = [...formData.fields];
    updatedFields[index].value = newValue;
    setFormData({ ...formData, fields: updatedFields });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
   
  
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Form Data');
     
  
      const labels = [];
      const values = [];

      labels.push('Detail');
      values.push('Value');
      
      labels.push('Customer Name');
      values.push(customerName);

      labels.push('TemplateName');
      values.push(selectedFile);
      
      formData.fields.forEach((field) => {
        labels.push(field.label);
        values.push(field.value);
      });

    
      worksheet.getColumn(1).values = labels;
      worksheet.getColumn(2).values = values;

      //worksheet.addRow(labels);
      //worksheet.addRow(values);
  
  
      const excelBuffer = await workbook.xlsx.writeBuffer();

      
     
      const bucketName = bucket
      const s3 = new AWS.S3({ region: config.region, credentials: credentials });

  
      let val = customerName;

      
      

     
      if (val !== '') {
        const params = {
          Bucket: bucketName,
          Key: val + '.xlsx',
          Body: excelBuffer,
          ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
  
        const uploadResult = await s3.upload(params).promise();
       
     
        setFileStatus('Submitted Successfully.\n');
        setFormData(null);
      setCustomerName('');
      setSelectedFile('');
      } else {
        console.error('val not found');
        setFileStatus('failed! Please try again!\n');
      }
    } catch (error) {
      console.error('Error:', error);
      setFileStatus('failed! Please try again!\n');
    }
  };
  

  const renderForm = () => {
    if (!formData) return null;

    return (

      <form >
        
       {/* <h4>{formData.templateName}</h4>*/}
       
          <Form.Label>Customer Name</Form.Label>
          <Form.Control  className="form-field"type="text" required={formData.fields[0].required}  placeholder="Enter customer name" value={customerName}  onChange={(e) => setCustomerName(e.target.value)}
          />{formData.fields.map((field, index) => (
          
            <div key={index} className="form-field">
     
           <Form.Label>{field.label}</Form.Label>
            {field.type === 'text' ? (
              <Form.Control type="text" value={field.value}  required={field.required} onChange={(e) => handleFieldChange(index, e.target.value)}/>
            ) : (
              
              <Form.Control as="select"  value={field.value}  required={field.required} onChange={(e) => handleFieldChange(index, e.target.value)} > 
              <option value="selectopt ">Select</option>
                {field.options.map((option, idx) => (
                  
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
             </Form.Control>
            )}
          </div>
        ))}
        
      </form>
    );
  };

  const hasNonEmptyText = /\S/.test(fileStatus);
  return (
    <div >
      <h2 className="heading">Workload Assessment Pro</h2>
      <h3 className="heading2">Add Customer Assessment</h3>
    
      
    <Container>
     
      <div className="product-description">
      Tool provides a framework with pre-defined questionnaire to assess customer workloads. This includes operational aspects and resource requirements which will define migration strategy for the customer. </div>
     
      <Form className="scrollable-form" onSubmit={handleSubmit}>
      

      <Form.Group className="form-field">
        <Form.Label>Select Template</Form.Label>
        <Form.Control
          as="select"
          value={selectedFile}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setSelectedFile(selectedValue);
            selectedTemp(selectedValue); // Call your function with the selected value
          }}
        >
          <option value="">Select a file</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {fileName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group>
      <div>
{renderForm()}
</div>
      
    </Form.Group>
   
    <Button variant="primary" type="submit" disabled={!isButtonEnabled} onClick={handleUpdateDB}>
          Submit
        </Button>
    </Form>

    {hasNonEmptyText && (
        <div className="success-message with-background">
          {fileStatus}
        </div>
      )}
</Container>
 {/*
<div>&nbsp;</div>

<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b><a className="phone-link" href="https://phonetool.amazon.com/users/ebkuriak">Eby Kuriakose</a></b></span></p>
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
      </div>
    </div>

*/}
</div>
  );
}

export default App;
