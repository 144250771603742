// dynamoDbService.js

import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import config from './appenvdata.json'; // Adjust the path as needed
import { Amplify, Auth } from 'aws-amplify';





Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}

AWS.config.update({
  accessKeyId: credentials.accessKeyId,
  secretAccessKey: credentials.secretAccessKey,
  sessionToken: credentials.sessionToken,
  region: config.region,

});


export const addEntryToDynamoDB = async (customer_name,toolName,userEmail, userFirstName,userLastName) => {
  const dynamoDb = new AWS.DynamoDB.DocumentClient();

  const dbTableName = config.dbTableName ;



  const id = uuidv4();
  const params = {
    TableName: dbTableName, 
    Item: {
      ID: id,
      ToolName: toolName,
      Customer: customer_name,
      Email: userEmail,
      FirstName:userFirstName,
      LastName:userLastName,
      Timestamp: new Date().toISOString() // Use ISO format for the timestamp
    }
  };

  try {
    await dynamoDb.put(params).promise();

  } catch (error) {
    console.error('Error adding entry to DynamoDB:', error);
  }
};

