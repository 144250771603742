import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="sticky-footer">
      <div className="footercontainer">
        <p>&copy; {currentYear} Amazon Web Services, Inc. or its affiliates. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
