import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

 

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {

  

  return (
    <div >
      <h2 class="heading">Modernization At Scale</h2>
    
      
    <Container>

     

     
      <div class="product-description">
        
      <b>Modernization Score generator</b> <br></br>Structured methodology to validate likely candidature of an application for Modernization. <br></br><br></br>
<b>Qualification checklist</b><br></br>A simple checklist to evaluate Modernization potential for a customer. You can use this checklist for MAP/MAP Lite and for Modernization EBA qualification.<br></br><br></br>
<b>Modernization Estimator</b> <br></br>This tool provides a quick ball-park estimate for modern application architecture.
  </div>
 
</Container>

<div class="left-aligned-container">
        <h3>About</h3>
        <p>Owner: <span id="version-number">  <a class="phone-link" href="https://phonetool.amazon.com/users/aloksinr">Alok Singh</a></span></p>
       {/*  <p>Current Version: <span id="version-number"><b>1.1</b></span></p> 
       
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>*/}
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <br></br>
         {/* 
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
         */}
      </div>
    </div>





  );
}

export default App;
